import { Component, OnInit } from '@angular/core';
import { ElasticService } from '../../services/elastic.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-datensammlung',
  templateUrl: './datensammlung.component.html',
  styleUrls: ['./datensammlung.component.scss']
})
export class DatensammlungComponent implements OnInit {


document134: string;
document135: string;
document136: string;
document159: string;
document155: string;

license: string;

  constructor(
    private es: ElasticService,
    private route: ActivatedRoute
    ) {

      const id = this.route.snapshot.paramMap.get('id');


      this.es.documentById134(id).then( res => {
        this.document134 = res;
        console.log(this.document134);


        if (this.document134['_source']['134_datacollectionlink134']) {
          // set default value for each term as 0
          this.license = this.document134['_source']['134_datacollectionlink134'];
          this.es.documentById155(this.license).then( res155 => {
            this.document155 = res155;
            console.log(this.document155);
          });
        } else {

        }


        if (this.document134['_source']['134_licenselink159']) {
          // set default value for each term as 0
          this.license = this.document134['_source']['134_licenselink159'];
          this.es.documentById159(this.license).then( res159 => {
            this.document159 = res159;
            console.log(this.document159);
          });
        } else {

        }
      });

    }

  ngOnInit() {
  }

}
