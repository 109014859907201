
import { SearchSettings } from '../../typings/search-settings';

export const FretSearchSettings: SearchSettings = {
    facets: [
        {
            id: 'fudclass',
            title: 'Klassifikation',
            facetKeys: ['161_facet_pfudclass', '83_documenttype.content', '81_verzeichnungsstufe.content', '88_archivtyp.content']
        },
        {
            id: 'person',
            title: 'Personen / Institutionen / Gruppen',
            facetKeys: ['161_facet_index_personenindex_8']
        },
        {
            id: 'Keywords',
            title: 'Schlagworte',
            facetKeys: ['161_facet_keywords']
        },
        {
            id: 'index_raum',
            title: 'Orte',
            facetKeys: ['161_facet_pplacesindex']
        }
    ],
    validDocTypes: ['doctyp161', 'doctyp83', 'doctyp81', 'doctyp88']
};
