import { Component, OnChanges, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { MerklisteService } from '../services/merkliste.service';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, OnChanges {

  @Input()
  document: any;

  @Output()
  infoRequest: EventEmitter<string> = new EventEmitter<string>();

  checked: boolean;

  constructor(
    private merklisteService: MerklisteService,
    public snackBar: MatSnackBar
  ) {
    this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
      this.checked = this.merklisteService.hasId(this.document['_source']['ID']);
    });
  }

  ngOnInit() {
    this.checked = this.merklisteService.hasId(this.document['_source']['ID']);
  }

  ngOnChanges(changes: any) {
    // console.log(changes);
  }

  public onMerklistToggle(event: any, document: any) {
    const snackBarMessage: string = (event.checked) ?
      'Zur Merkliste hinzugefügt' : 'Von Merkliste entfernt';
    this.snackBar.open(snackBarMessage, '', {
      duration: 1000
    });
    if (event.checked) {
      this.merklisteService.registerItem(document);
    } else {
      this.merklisteService.unregisterItem(document);
    }
  }

  public onInfoClick() {
    this.infoRequest.emit(this.document['_source']['ID']);
  }

}
