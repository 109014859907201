import { Component, OnInit, Input } from '@angular/core';
import { ElasticService } from '../../services/elastic.service';
import { ActivatedRoute } from '@angular/router';
// import { AnfrageDateiComponent } from 'src/app/dialogues/anfrage-datei/anfrage-datei.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AnfrageDialogComponent } from 'src/app/anfrage-dialog/anfrage-dialog.component';


@Component({
  selector: 'app-dokument',
  templateUrl: './dokument.component.html',
  styleUrls: ['./dokument.component.scss']
})
export class DokumentComponent implements OnInit {

  document: string;
  license: string;
data: string;




  constructor(
    private es: ElasticService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
    ) {

      // Dokument 136
      // Teilsammlung 135
      // Datensammlung 134
      // Lizens 159

      // this.es.getAll155   .search({

      //  });

//       this.es.getAll155().then( res155 => {
//         this.document155 = res155;
//         this.document155 = res155.hits.hits['_source']['155_doclink'];
//         console.log('alle Docs 155' + JSON.stringify(this.document155));
//       });



//       const id = this.route.snapshot.paramMap.get('id');



//       this.es.documentById136(id).then( res => {
//         this.document136 = res;
//         console.log(this.document136);



//         // Link zur Datensammlung (einfach)
//         if (this.document136['_source']['136_datacollectionlink136']) {
//           // set default value for each term as 0
//           this.data = this.document136['_source']['136_datacollectionlink136'];
//           this.es.documentById134(this.data).then( res134 => {
//             this.document134 = res134;
//             console.log(this.document134);
//           });
//         } else {

//         }
//  // Link zur Teilsammlung (einfach)
//         if (this.document136['_source']['136_archivpartlink']) {
//           // set default value for each term as 0
//           this.data = this.document136['_source']['136_archivpartlink'];
//           this.es.documentById135(this.data).then( res135 => {
//             this.document135 = res135;

//              // Link zur Datensammlung (einfach)
//             console.log(this.document135);
//             if (this.document135['_source']['135_datacollectionlink135']) {
//               this.data = this.document135['_source']['135_datacollectionlink135'];
//               this.es.documentById134(this.data).then( res134 => {
//                 this.document134 = res134;
//                 console.log(this.document134);
//             });
//             } else {

//             }


//           });
//         } else {

//         }

//  // Link zur Lizens
//         if (this.document136['_source']['136_licenselink159']) {
//           // set default value for each term as 0
//           this.license = this.document136['_source']['136_licenselink159'];
//           this.es.documentById159(this.license).then( res159 => {
//             this.document159 = res159;
//             console.log(this.document159);
//           });
//         } else {

//         }
//       });
    }

     openDialog(): void {
      const dialogRef = this.dialog.open(AnfrageDialogComponent, {
        width: '900px',
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }


    public onMerklistToggle(event: any) {
      const snackBarMessage: string = (event.checked) ?
        'Zum Cart hinzugefügt' : 'Zum Cart hinzugefügt';
      this.snackBar.open(snackBarMessage, '', {
        duration: 1000
      });
    }

  ngOnInit() {
  }

}
