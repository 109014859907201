import { Component, OnInit } from '@angular/core';
import { ElasticService } from '../../services/elastic.service';
import { HttpService } from '../../services/http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teilsammlung',
  templateUrl: './teilsammlung.component.html',
  styleUrls: ['./teilsammlung.component.scss']
})
export class TeilsammlungComponent implements OnInit {

  document79: string;
  document135: string;
  document136: string;
  document159: string;


  constructor(
    private es: ElasticService,
    private http: HttpService,
    private route: ActivatedRoute
  ) {

    const id = this.route.snapshot.paramMap.get('id');



    // this.http.documentById(id).then( res => {
    //   this.document79 = res;
    //   console.log(this.document79);
    // });



   }

  ngOnInit() {
  }


}
