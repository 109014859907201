import { Injectable } from '@angular/core';
import { Client } from 'elasticsearch-browser';

@Injectable({
  providedIn: 'root'
})
export class ElasticService {
  private client: Client;
  indexname = 'fud_fret_web';
  typename = 'doctyp134,doctyp135,doctyp136';


  constructor() {
    if (!this.client) {
      this.connect();
    }
  }

  private queryalldocs = {
    'query': {
      'match_all': {}
    }
  };


  private connect() {
    this.client = new Client({
      host: 'http://localhost:9210',
      // log: 'trace'
    });
  }

  isAvailable(): any {
    return this.client.ping({
      requestTimeout: Infinity,
      body: 'Query is working'
    });
  }


  Search(body: any): any {

    return this.client.search({
      index: this.indexname,
      type: this.typename,
      filterPath: ['hits.hits._source', 'hits.total', '_scroll_id', 'facets'],
      body
    });
  }

  documentById(id: any, docType: string): any {
    return this.client.get({
      id: id,
      index: this.indexname,
      type: docType,
    });
  }

  documentById134(id: any): any {
    return this.client.get({
      id: id,
      index: this.indexname,
      type: 'doctyp134',
    });
  }

  documentById135(id: any): any {
    return this.client.get({
      id: id,
      index: this.indexname,
      type: 'doctyp135',
    });
  }

  documentById136(id: any): any {
    return this.client.get({
      id: id,
      index: this.indexname,
      type: 'doctyp136',
    });
  }

  documentById159(id: any): any {
    return this.client.get({
      id: id,
      index: this.indexname,
      type: 'doctyp159',
    });
  }

  documentById155(id: any): any {
    return this.client.get({
      id: id,
      index: this.indexname,
      type: 'doctyp155',
    });
  }

  getAll155(): any {
    return this.client.search({
      index: this.indexname,
      type: 'doctyp155',
      body: this.queryalldocs,
      filterPath: ['hits.hits._source']
    });
  }

}
