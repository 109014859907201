import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ElasticService } from '../services/elastic.service';
import { HttpService } from '../services/http.service';
import { PageEvent, MatSnackBar, MatPaginator } from '@angular/material';
import { FudElasticQuery } from '../../typings/fud-elastic-query';
import { QueryBuilderService } from '../services/query-builder.service';
import { FretSearchSettings } from '../config/fret-search-settings';
import { SearchSettings, FacetSetting } from '../../typings/search-settings';
import { Category } from './text-search/text-search.component';
import { Sorting } from './text-search/text-search.component';
import { FilterEvent } from './filter/filter.event';
import { FilterComponent } from './filter/filter.component';
import { Options } from 'ng5-slider';


export interface FacetResult {
  term: string;
  count: number;
  selected: boolean;
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchSettings: SearchSettings = FretSearchSettings;

  selectedCategory: Category;
  selectedSorting: Sorting;

  facetDict: any = {};
  // will map facet ids (e.g. '134_facet_location') to
  // the keyword ('location') by iterating over the facetKeys
  facetIdsToId: any = {};

  // (uses object notation, for each facetId a list of selected topics)
  selectedTopics = {};

  isLoadingResults = false;

  @ViewChild(FilterComponent) filter;

  count: number;
  private documents: Document[] = [];

  // Slider
  // vonValue: 100;
  // bisValue: 2000;
  // options: Options = {
  //   floor: 0,
  //   ceil: 10,
  //   animate: false
  // };


  // MatPaginator Output
  pageEvent: PageEvent;
  pageSize: any = '25';
  pageIndex: any = '0';
  length = this.count;
  lowValue: any = '0';
  highValue: any = '50';
  pageSizeOptions: number[] = [5, 10, 25, 100];
  date_von = new Date('01/01/1000');
  date_bis = new Date().toISOString().split('T')[0];


  queryText: string;


  constructor(
    private es: ElasticService,
    private http: HttpService,
    private snackBar: MatSnackBar,
    private queryBuilder: QueryBuilderService,
    private router: Router

  ) {


  }

  ngOnInit() {
    this.searchSettings.facets.forEach((facet: FacetSetting) => {
      // populate facetDict used to pass information to filter boxes
      this.facetDict[facet.id] = {
        'keys': facet.facetKeys,
        'values': [],
        'selectedTerms': []
      };
      // Map all facet keys to the id (to check for existence on return)
      facet.facetKeys.forEach((facetKey: string) => {
        this.facetIdsToId[facetKey] = facet.id;
      });
    });
    this.queryBuilder.setSettings(this.searchSettings);
    this.submitSearch();

    // this.date_von = this.date_von.value.toISOString().split('T')[0];
    // this.date_bis = this.date_bis.value.toISOString().split('T')[0];
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',')
      .map(str => +str);
  }

  // getting selected Facets
  onSelectionChange(event: FilterEvent) {
    console.log(event);
    // LESSON-PETER: hier stand jeweils noch das $event
    // das dollar-zeichen wird nur als special character
    // in der view verwendet, um anzuzeigen, dass das event
    // this.selectedTopics[$event.type] = $event.topics;
    // als parameter verwendet wird. in der .ts datei kann
    // und sollte man einfach nur event schreiben
    this.selectedTopics[event.type] = event.topics;
    this.facetDict[event.type]['selectedTerms'] = event.selectedTerms;
    this.submitSearch();
  }

  getPaginatorData(event: PageEvent) {
    if (event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + this.pageSize;
      this.highValue = this.highValue + this.pageSize;
    } else if (event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - this.pageSize;
      this.highValue = this.highValue - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
    this.submitSearch();
  }


  // TODO Bester weg für den Facet reset
 resetFacets() {
    console.log(this.filter.facets.active);
    this.filter.facets.active = [];
    console.log(this.filter.facets.active);
    this.submitSearch();
  }

  public onInfoRequest(documentId: string) {
    this.router.navigate(['document', documentId]);
  }

  public onTextSearch(searchText: any) {
    this.queryText = searchText.queryText;
    this.selectedCategory = searchText.category;
    this.selectedSorting = searchText.sorting;
    this.submitSearch();
  }

  submitSearch(event?: PageEvent) {

    const query: FudElasticQuery = this.queryBuilder.buildQuery(
      this.queryText,
      this.selectedCategory,
      // this.selectedSorting,
      this.date_von,
      this.date_bis,
      this.selectedTopics,
      this.pageSize,
      this.pageIndex
    );

    // console.log(this.date_von.value.toISOString().split('T')[0]);
    // console.log(this.date_bis.value.toISOString().split('T')[0]);

    this.http.Search(query).subscribe(
      data => {
          console.log(data.hits);
          console.log(data);
          console.log(query);
      }
  );

  // this.es.Search(query).then(
  //   data => {
  //       console.log(data);
  //   }
// );

    this.http.Search(query).subscribe(
      (response: any) => {
        this.isLoadingResults = true;
        setTimeout(() => {
          // console.log(query);
          // console.log(response.took);

          // TODO: necessary? used to rebuild data-table?
          // could possibly use a refresh method
          this.pageIndex = this.pageIndex;
          this.pageSize = this.pageSize;
          this.length = this.length;

          this.isLoadingResults = false;
          this.documents = response.hits.hits;
          this.count = response.hits.total;

          if (!response.facets) {
            return false;
          }

          // temporary object to collect all information
          // to avoid triggering change-detection too often
          const tmpFacets = {};
          let currId: string;
          // tslint:disable-next-line:forin
          for (let facetId in response.facets) {
            currId = this.facetIdsToId[facetId];
            console.log(`${facetId} => ${currId}`);
            if (currId !== undefined) {
              if (tmpFacets[currId] === undefined) {
                tmpFacets[currId] = [];
              }
              tmpFacets[currId] = tmpFacets[currId].concat(
                response.facets[facetId].terms);
            }
          }

          // tslint:disable-next-line:forin
          for (let facetId in tmpFacets) {
            tmpFacets[facetId] = this._reduceFacets(
              tmpFacets[facetId], facetId);
          }
          // Now actually assign them to the facetDict (triggering change detection)
          // tslint:disable-next-line:forin
          for (let facetId in tmpFacets) {
            this.facetDict[facetId].values = tmpFacets[facetId];
          }
        }, 500);
      }, (error: any) => {
        console.error(error);
      });
  }

  /**
   * Reduce all facets by setting defaults and adding up values
   */
  private _reduceFacets(facets: FacetResult[], keywordId: string): { active: FacetResult[], inactive: FacetResult[] } {
    // using reducer on all terms
    const mergedFacets = {};
    console.log(facets);
    facets.forEach((facet: FacetResult) => {
      facet.count = facet.count || 0;
      if (mergedFacets[facet.term] === undefined) {
        mergedFacets[facet.term] = Object.assign(facet, {});
      } else {
        mergedFacets[facet.term].count = mergedFacets[facet.term].count + facet.count;
      }
    });
    const activeFacets: FacetResult[] = [];
    const inactiveFacets: FacetResult[] = [];
    Object.keys(mergedFacets).forEach((facetKey: string) => {

      if (this.facetDict[keywordId].selectedTerms.indexOf(facetKey) > -1) {
        activeFacets.push(mergedFacets[facetKey]);
      } else {
        inactiveFacets.push(mergedFacets[facetKey]);
      }
    });

    activeFacets.sort(function (b, a) { return a.count - b.count; });
    inactiveFacets.sort(function (b, a) { return a.count - b.count; });
    return {
      active: activeFacets,
      inactive: inactiveFacets
    };
  }


  onPrint() {
    window.print();
}

resetSearch() {
  window.open('https://minerva-develop.kunstgesch.uni-halle.de/suche/', '_blank', '');
}

}
