import { Component, OnInit } from '@angular/core';
import { AccountOption } from './account.interface';

import { Router } from '@angular/router';
import { MerklisteService } from '../services/merkliste.service';


export interface PeriodicElement {
  name: string;
  position: number;
  zugang: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Ratsmanual: Register', zugang: 'auf Anfrage'},
  {position: 2, name: 'Weisungen Synode', zugang: 'abgelehnt'},
  {position: 3, name: 'Von der Kunst reich zu werden', zugang: 'freigeschaltet'},
  {position: 4, name: 'Bettelordnung', zugang: 'auf Anfrage'}

];



@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  // Merkliste
  documents: any[];


  // Anfragen
  displayedColumns: string[] = ['position', 'name', 'zugang'];
  dataSource = ELEMENT_DATA;


  // Menüpunkte
  currentAccountOption: AccountOption;
  accountOptions: AccountOption[] = [
    {
      name: 'Profil',
      icon: 'account_box',
      display: false,
      id: '1'
    },
    {
      name: 'Meine Datensammlung(en)',
      icon: 'collections_bookmark',
      display: false,
      id: '2'
    },
    {
      name: 'Freigegebene Datensammlung(en)',
      icon: 'filter_none',
      display: false,
      id: '3'
    },
    {
      name: 'Download Aufträge',
      icon: 'arrow_downward',
      display: false,
      id: '4'
    },
    {
      name: 'Merkliste',
      icon: 'bookmarks',
      display: false,
      id: '5'
    },
    {
      name: 'Verlauf',
      icon: 'history',
      display: false,
      id: '6'
    },
    {
      name: 'Zugriffsanfragen',
      icon: 'mail_outline',
      display: false,
      id: '7'
    }
  ];





  constructor(
    private merklisteService: MerklisteService,
    private router: Router,
  ) {
    this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
      this.documents = this.merklisteService.getAllObjects();
    });
   }

  ngOnInit() {
    this.currentAccountOption = this.accountOptions[0];

    this.documents = this.merklisteService.getAllObjects();
  }

  activateAccountOption(accountOption: AccountOption) {
    this.currentAccountOption = accountOption;
  }

  public onInfoRequest(documentId: string) {
    this.router.navigate(['document', documentId]);
  }


}
