import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


export interface Category {
  value: any;
  viewValue: string;
}

export interface Sorting {
  value: any;
  viewValue: string;
}



@Component({
  selector: 'app-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss']
})
export class TextSearchComponent implements OnInit {

  queryText: string;

  categories: Category[] = [
    { value: ['_all'], viewValue: 'Volltext' },
    // { value: ['79_facet_obj_type'], viewValue: 'Art des Objektes' },
    // { value: ['79_facet_standort_aktuell'], viewValue: 'Standort' },
    // { value: ['79_facet_standort_ehem'], viewValue: 'Ehemaliger Standort' },
    // { value: ['79_date'], viewValue: 'Datierung' },
    // { value: ['79_facet_kuenstler'], viewValue: 'Künstler/Werkstatt' },
    // { value: ['79_facet_verortung'], viewValue: 'künstlerische Herkunft' },
    // { value: ['79_facet_stifter'], viewValue: 'Stifter/Auftraggeber' },
    // { value: ['79_facet_ikonographie'], viewValue: 'Ikonographie' }
  ];
  selectedCategory = this.categories[0].value;

  sortings: Sorting[] = [
    { value: ['a-z'], viewValue: 'A-Z' },
    { value: ['z-a'], viewValue: 'Z-A' }
  ];
  selectedSorting = this.sortings[0].value;


  @Input()
  count: number;

  // TODO: should be typed to the emit
  @Output()
  search: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    console.log(this.categories);
    console.log(this.sortings);
  }

  submitSearch() {
    this.search.emit({
      queryText: this.queryText,
      category: this.selectedCategory,
      sorting: this.selectedSorting
    });
  }

}
