import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { DocumentViewComponent } from './document-view/document-view.component';

const routes: Routes = [
  { path: '', redirectTo: '/search', pathMatch: 'full', runGuardsAndResolvers: 'always'},
  { path: 'search', component: SearchComponent, runGuardsAndResolvers: 'always'},
  { path: 'document/:id',      component: DocumentViewComponent, runGuardsAndResolvers: 'always' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
