import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { HttpService } from '../services/http.service';
import { ActivatedRoute } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';






@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {

  document161: string;
  document88: string;
  document83: string;
  document81: string;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  // document136: string;
  // document159: string;

  license: string;
  ungedruckteQuellenID = [];
  linksto81 = [];
  gedruckteQuellenID = [];
  linksto83 = [];



  textComments: any[] = [];
  contentComments: any[] = [];
  highlightLinks = true;
  archivId: any;
  references: any;
  urheber: any;

  berufe = [];
  geburt = [];
  geburtsort = [];
  tod = [];
  sterbeort = [];
  begrort = [];
  begrtod = [];
  religion = [];




  constructor(
    private http: HttpService,
    private route: ActivatedRoute,
    private el: ElementRef,
    // private _location: Location,
  ) {

  }

  // LESSON-PETER: Die ganze Logik war im constructor, allgemein
  //   empfiehlt es sich die Logik in ngOnInit zu packen, teils
  //   wurde das Dokument nicht geladen
  ngOnInit() {

    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);

    this.http.documentById161(id).subscribe(res => {
      this.document161 = res;



      // Berufe
      for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
        let events = this.document161['_source']['161_Events'][i];
        console.log(events);
        for (let j = 0; j < events['peventsub'].length; j++) {

         if (events['peventsub'][j].parentID === '2935') {
          this.berufe.push(events['peventsub'][j].content);
        }
        }
      }

      // Geburt
      for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
        let events = this.document161['_source']['161_Events'][i];
        console.log(events);
        for (let j = 0; j < events['peventsub'].length; j++) {

         if (events['peventsub'][j].ID === '15') {
          this.geburt.push(events['pstdatesub']);
        }
        }
      }

      // Geburtsort
      for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
        let events = this.document161['_source']['161_Events'][i];
        console.log(events);
        for (let j = 0; j < events['peventsub'].length; j++) {

         if (events['peventsub'][j].ID === '15') {
          this.geburtsort.push(events['pplacesindex'][j].content);
        }
        }
      }

        // Tod
        for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
          let events = this.document161['_source']['161_Events'][i];
          console.log(events);
          for (let j = 0; j < events['peventsub'].length; j++) {
           if (events['peventsub'][j].ID === '22') {
            this.tod.push(events['pstdatesub']);
          }
          }
        }


             // Sterbeort
      for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
        let events = this.document161['_source']['161_Events'][i];
        console.log(events);
        for (let j = 0; j < events['peventsub'].length; j++) {

         if (events['peventsub'][j].ID === '22') {
          this.sterbeort.push(events['pplacesindex'][j].content);
        }
        }
      }

       // Begräbnistag
       for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
        let events = this.document161['_source']['161_Events'][i];
        console.log(events);
        for (let j = 0; j < events['peventsub'].length; j++) {
         if (events['peventsub'][j].ID === '2928') {
           if (events['pstdatesub']) {
            this.begrtod.push(events['pstdatesub']);
           }
        }
        }
      }


           // Begräbnisort
    for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
      let events = this.document161['_source']['161_Events'][i];
      console.log(events);
      for (let j = 0; j < events['peventsub'].length; j++) {
       if (events['peventsub'][j].ID === '2928') {
        this.begrort.push(events['pplacesindex'][j].content);
      }
      }
    }


           // Religion
           for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
            let events = this.document161['_source']['161_Events'][i];
            console.log(events);
            for (let j = 0; j < events['peventsub'].length; j++) {
             if (events['tf_kategorie'][j].ID === '2931') {
              this.religion.push(events['peventsub'][j].content);
            }
            }
          }





      for (let i = 0; i < this.document161['_source']['161_Events'].length; i++) {
        let events = this.document161['_source']['161_Events'][i];
        console.log(events);
        for (let j = 0; j < events['peventsub'].length; j++) {

         if (events['peventsub'][j].parentID === '2935') {
          this.berufe.push(events['peventsub'][j].content);
        }
        }
      }

      // ungedruckte Quellen links to 81
      if (this.document161['_source']['161_References']) {
        this.references = this.document161['_source']['161_References'];
      }

      for (let i = 0; i < this.references.length; i++) {

        for (let j = 0; j < this.references[i].ptyperef.length; j++) {
          if (this.references[i].ptyperef[j].ID === '51') {
           this.ungedruckteQuellenID.push(
              this.references[i].plinklibrarysub
             );
          }
        }
      }
      for (let i = 0; i < this.ungedruckteQuellenID.length; i++) {
        this.http.documentById81(this.ungedruckteQuellenID[i]).subscribe(linkedDocs81 => {

          this.archivId = linkedDocs81['_source']['81_aufbewahrungsort'];

          this.http.documentById88(this.archivId).subscribe(ArchivInfo => {
            console.log(ArchivInfo);

            this.linksto81.push(
              {
                name: linkedDocs81['_source']['81_titel'],
                signatur: linkedDocs81['_source']['81_signatur'],
                id: linkedDocs81['_id'],
                archiv: ArchivInfo['_source']['88_name'],
                archivIsl: ArchivInfo['_source']['88_isil_nr'],
                archivId: this.archivId
              }
            );
          });
        });


      }
      console.log(this.linksto81);






      for (let i = 0; i < this.references.length; i++) {

        for (let j = 0; j < this.references[i].ptyperef.length; j++) {
          if (this.references[i].ptyperef[j].ID === '50') {
           this.gedruckteQuellenID.push(
              this.references[i].plinklibrarysub
             );
          }
        }
      }
      // console.log(this.gedruckteQuellenID);
      for (let i = 0; i < this.gedruckteQuellenID.length; i++) {
        this.http.documentById83(this.gedruckteQuellenID[i]).subscribe(linkedDocs83 => {
          // console.log(linkedDocs83);
          if (linkedDocs83['_source']['83_documenttitle'] && linkedDocs83['_id']) {



            this.linksto83.push(
              {
                name: linkedDocs83['_source']['83_documenttitle'],
                ort: linkedDocs83['_source']['83_adress'],
                date: linkedDocs83['_source']['83_date_standard'],
                urheber: linkedDocs83['_source']['83_author'],
                id: linkedDocs83['_id']
              }
            );
          }
        });
      }
      console.log(this.linksto83);

      this.document161['_source']['notes'].forEach((note) => {
        switch(note['category']) {
          case 'Sachkommentar':
          this.contentComments.push(note);
          break;
          case 'Textkritik':
          this.textComments.push(note);
          break;
        }
      });


       console.log(this.document161['_source']);
    });

    this.http.documentById88(id).subscribe(res => {
      this.document88 = res;

      console.log(this.document88['_source']);
    });

    this.http.documentById83(id).subscribe(res => {
      this.document83 = res;
      console.log(this.document83['_source']);
    });

    this.http.documentById81(id).subscribe(res => {
      this.document81 = res;
      console.log(this.document81['_source']);
    });





}


public highlightNotes(event: any, indexItem: any, prefix: string, className: string) {
  console.log(event);
  let id = indexItem.ID;
  if (indexItem.selected === undefined) {
    indexItem.selected = true;
  } else {
    indexItem.selected = !indexItem.selected;
  }
  let selected = indexItem.selected;
  console.log(selected);

  // if (this.highlightLinks) {
    let nativeEl: HTMLElement = this.el.nativeElement;
    let spans = nativeEl.getElementsByClassName(prefix + id);
    let currSpan;
    // keep track if scrolled, to scroll into view
    let scrolled = false;
    for (let i = 0; i < spans.length; i++) {
      currSpan = spans.item(i);
      console.log(currSpan);
      // currSpan.scrollIntoView();
      if (selected) {
        currSpan.classList.add(className);
        if (!scrolled) {
          currSpan.scrollIntoView();
          scrolled = true;
        }
      } else {
        currSpan.classList.remove(className);
      }
    }
  // }
}

public stripTags(content: string) {
  content = content.replace(/<\/?.*?>/g, "");
  return content;
}



}



