import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { MatDialogRef } from '@angular/material/dialog';

import { MerklisteService } from '../services/merkliste.service';


@Component({
  selector: 'app-merkliste-dialog',
  templateUrl: './merkliste-dialog.component.html',
  styleUrls: ['./merkliste-dialog.component.scss']
})
export class MerklisteDialogComponent implements OnInit {

  documents: any[];

  constructor(
    private merklisteService: MerklisteService,
    private router: Router,
    public dialogRef: MatDialogRef<MerklisteDialogComponent>
    ) {
      this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
        this.documents = this.merklisteService.getAllObjects();
      });
    }

  ngOnInit() {
    this.documents = this.merklisteService.getAllObjects();

  }

  public onInfoRequest(documentId: string) {
    this.router.navigate(['document', documentId]);
    this.dialogRef.close();
  }

}
