export const environment = {
  production: true,

  elastic_url_search: '/assets/api/elastic.php',
  elastic_url_document161: '/assets/api/elastic-document161.php',
  elastic_url_document88: '/assets/api/elastic-document88.php',
  elastic_url_document83: '/assets/api/elastic-document83.php',
  elastic_url_document81: '/assets/api/elastic-document81.php'

};
