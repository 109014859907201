/**
 * Builds queries by adding and removing facets
 */

import { Injectable } from '@angular/core';

import { FudElasticQuery } from '../../typings/fud-elastic-query';
import { SearchSettings, FacetSetting } from '../../typings/search-settings';


@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {

  // Could or should be removed
  currQuery: FudElasticQuery;
  // Build from the settings file
  facetObj: any;
  validDocTypes: any[] = [];

  sortArray: any[] = [];

  constructor(
  ) {
  }

  public setSettings(settings: SearchSettings) {
    const currFacetObj = {};
    // build the facet objects to be returned
    // from the query
    settings.facets.forEach((facetSetting: FacetSetting) => {
        facetSetting.facetKeys.forEach((facetKey: string) => {
          currFacetObj[facetKey] = {
            'terms': {
              'field': facetKey,
              'size' : 99
          }
          };
        });
    });
    this.facetObj = currFacetObj;
    settings.validDocTypes.forEach((docType: string) => {
      this.validDocTypes.push({
        'type': {
          'value': docType
        }
      });
    });
  }

  public setQuery(query: FudElasticQuery) {
    this.currQuery = query;
  }

  public getQuery(): FudElasticQuery {
    return this.currQuery;
  }

  public buildQuery(
    searchText: string,
    selectedCategory: any,
    date_von: any,
    date_bis: any,
    facetPhrases: any,
    pageSize: number,
    pageIndex: number): FudElasticQuery {

    const should = facetPhrases;
    const topicBooleans = [];
    // tslint:disable-next-line:forin
    for (const member in facetPhrases) {
      // tslint:disable-next-line:forin
      for (const subMember in facetPhrases[member]) {
        topicBooleans.push({
          'bool': {
            'should': facetPhrases[member][subMember]
          }
        });
      }
    }

    // if (date_von) {
    //   topicBooleans.push({

    //                   'range': {
    //                       '79_date_from': {
    //                           'gte': date_von
    //                       }
    //                   }

    //   });
    // }

    // if (date_bis) {
    //   topicBooleans.push({
    //                   'range': {
    //                       '79_date_to': {
    //                           'lte': date_bis
    //                       }
    //                     }
    //    });
    // }

    console.log(date_von);
    console.log(date_bis);
    console.log(topicBooleans);



    let textQuery: any;
    if (!searchText) {
      textQuery = { 'match_all': {} };
    } else {

      textQuery = {
        'multi_match': {
          'query': searchText,
          'type': 'phrase_prefix',
          // 'fields': ['79_material', '79_technik'] // Fulltext or Category
          'fields': selectedCategory
        }
      };
    }

    const must = [
            textQuery
    ];

    const query: FudElasticQuery = {
      // 'size': this.size.value, // document anzahl
      'size': pageSize,
      'sort': [
            // {
            //     '79_date_from': 'desc'
            // }
        ],
      'from': pageIndex, // page
      'query': {
        'filtered': {
          'filter': {
            'bool': {
              'must': [
                {
                  'bool': {
                      'should': [

                          /// TODO Auslagern in PHP ?
                          {
                              'term': {
                                  '161_status.ID': '3030'
                              }
                          },
                          {
                            'term': {
                                '161_status.ID': '3031'
                            }
                        },
                        {
                          'term': {
                              '161_pprojectclass.ID': '4'
                          }
                      },
                        {
                          'term': {
                              '88_bearbeitungsstatus.ID': '3030'
                          }
                      },
                      {
                        'term': {
                            '88_bearbeitungsstatus.ID': '3031'
                        }
                    },
                    {
                      'term': {
                          '83_status.ID': '3030'
                      }
                  },
                  {
                    'term': {
                        '83_status.ID': '3031'
                    }
                },
                {
                  'term': {
                      '81_bearbeitungsstatus.ID': '3031'
                  }
              },
              {
                'term': {
                    '81_bearbeitungsstatus.ID': '3031'
                }
            },
                      //   {
                      //     'term': {
                      //         '161_pprojectclass.ID': '4'
                      //     }
                      // }
                          //  {
                          //     'term': {
                          //         '135_archivemetadataaccess': 'öffentlich'
                          //     }
                          // },
                          // {
                          //     'term': {
                          //         '134_archivemetadataaccess': 'öffentlich'
                          //     }
                          // }
                      ]
                  }
              },
                {
                  'bool': {
                    'should': this.validDocTypes
                  },
                }
              ]
            }
          },
          'query': {
            'bool': {
              'must': {
                'bool': {
                  'must': must,
                  'should': [
                    {
                      'bool': {
                        'must': topicBooleans
                      }
                    }
                  ],
                  'minimum_should_match': 2
                }
              }
            }
          }
        }
      },
      'facets': this.facetObj,
      // 'facet_filter' : {}
    };
    return query;
  }


}
