import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class HttpService {


  constructor(
    private http: HttpClient) {
}






   Search(body: any): Observable<any> {
    const proxyBody = {
      // path: '/_search',
      body: JSON.stringify(body)
    };

    console.log('query = ' + JSON.stringify(body));
    // console.log(environment.elastic_url_search);
     return this.http.post( environment.elastic_url_search, proxyBody, {
      headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  });
   }

   documentById161(id: any): any {

    const proxyBody = {
      body: id
    };
     return this.http.post( environment.elastic_url_document161, proxyBody, {
      headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  });
 }

 documentById88(id: any): any {

  const proxyBody = {
    body: id
  };
   return this.http.post( environment.elastic_url_document88, proxyBody, {
    headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
});
}

documentById83(id: any): any {

  const proxyBody = {
    body: id
  };
   return this.http.post( environment.elastic_url_document83, proxyBody, {
    headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
});
}

documentById81(id: any): any {

  const proxyBody = {
    body: id
  };
   return this.http.post( environment.elastic_url_document81, proxyBody, {
    headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
});
}


}
