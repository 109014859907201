import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss']
})
export class CartDialogComponent implements OnInit {


  status: false;
  isDisabled: false;


  constructor(
    public dialogRef: MatDialogRef<CartDialogComponent>
  ) { }

  ngOnInit() {
  }



// clickEvent() {
//   this.status = !this.status;
//   this.isDisabled = !this.isDisabled;
// }

}
