import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ElasticService } from '../../services/elastic.service';

import { FilterEvent } from './filter.event';
import { HttpService } from 'src/app/services/http.service';



@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  // From Parent
  @Input()
  facets: any[];

  @Input()
  isLoadingResults: boolean;

  @Input()
  documents: Document[] = [];

  @Input()
  type: string;

  @Input()
  facetKeys: string[];

  public show = false;
  buttonName = 'Mehr Anzeigen';
  anzahl = '10';

  // Sending my facets to Parent
  @Output()
  selectionChange = new EventEmitter<FilterEvent>();

  // used to build the facet query for the query service
  selectedTopics = {};

  // Keep track of the selected Terms
  selectedTerms: string[] = [];

  constructor(
    // LESSON-PETER: s.u.
    // public searchComponent: SearchComponent,
    public es: ElasticService,
    public http: HttpService
  ) {

  }

  onChange(value: string, checked: boolean) {
    if (checked) {
      // add to selected Terms (selectedTerms is delegated to parent to keep track
      //    of active selection)
      this.selectedTerms.push(value);
      // build the matchphrase for the query
      let currMatchPhrase: any;
      this.selectedTopics[value] = [];
      this.facetKeys.forEach((key: string) => {
        currMatchPhrase = {};
        currMatchPhrase[key] = value;
        this.selectedTopics[value].push({
          'match_phrase': currMatchPhrase
        });
      });
    } else {
      this.selectedTerms = this.selectedTerms.filter((term: any) => {
        return term !== value;
      });
      delete this.selectedTopics[value];
    }

    this.selectionChange.emit({
      topics: this.selectedTopics,
      selectedTerms: this.selectedTerms,
      type: this.type
    });

    // LESSON-PETER: Das kann man schon machen,
    //   finde ich aber nicht so gut, da man es i.d.R. nicht erwartet
    //   erwarten würde man eher event delegation und dann aufruf der
    //   Methode im parent. In die andere Richtung (parent ruft public
    //   method des child) geht das schon eher
    // Bug anfällig war auch die injection der KOmponente. Wenn mehrere
    //  search.component vorhanden wären, hätte es vermutlich große Probleme
    //  gegeben
    // this.searchComponent.submitSearch();
    console.log(this.selectedTopics);
  }



  toggleAmount() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if ( this.show ) {
      this.buttonName = 'Weniger Anzeigen';
      this.anzahl = '99';
    } else {
      this.buttonName = 'Mehr Anzeigen';
      this.anzahl = '10';
    }
  }




  ngOnInit() {

  }

}
